const PythIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={`${className}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 76 95"
      fill="none"
    >
      <path
        d="M19.4677 5.5338C24.9839 2.3324 31.3861 0.5 38.2167 0.5C58.9288 0.5 75.7147 17.336 75.7147 38.1004C75.7147 58.8648 58.9288 75.7006 38.2167 75.7006V66.3006C53.7476 66.3006 66.3402 53.6737 66.3402 38.1004C66.3402 22.5271 53.7476 9.90013 38.2167 9.90013C34.9308 9.90013 31.772 10.4655 28.8422 11.5115C25.3485 12.7444 22.1728 14.6575 19.4677 17.0809C13.7142 22.2465 10.0932 29.7505 10.0932 38.1004V75.7006L0.71875 66.3006V38.1004C0.71875 28.5685 4.25909 19.8614 10.0932 13.2333C12.7729 10.1849 15.9358 7.57876 19.4677 5.5338Z"
        fill="#E6DAFE"
      />
      <path
        d="M38.216 47.4995C43.393 47.4995 47.5905 43.2905 47.5905 38.0994C47.5905 32.9082 43.393 28.6993 38.216 28.6993C33.0391 28.6993 28.8415 32.9082 28.8415 38.0994V94.5L19.467 85.1V38.0994C19.467 31.1397 23.2363 25.0642 28.8415 21.8161C31.5974 20.2133 34.8029 19.2992 38.216 19.2992C48.5699 19.2992 56.965 27.7172 56.965 38.0994C56.965 48.4816 48.5699 56.8995 38.216 56.8995V47.4995Z"
        fill="#E6DAFE"
      />
    </svg>
  )
}

export default PythIcon
